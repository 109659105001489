import React from "react";
import {Link} from 'gatsby';
import Seo from "../components/seo";
import { makeStyles } from '@material-ui/core/styles';
import {colors} from '../style/constants';
import InnerLinkButton from '../components/svgs/buttons/InnerLinkButton';
import {MagnetoBlog} from '../components/svgs/magneto-lito';

const useStyles = makeStyles(() => ({
  wrapper: {
    color: colors.ATT_BLUE,
    textAlign: 'center',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow:' column',
    position: 'fixed',
    top: 0,
    left: 0,
    '& > svg': {
      height: 300,
      margin: '30px 0'
    },
    '& span': {
      color: colors.TURQUOISE,
      display: 'block'
    },
    '& h1 span': {
      fontSize: '1em',
    },
    '& h2': {
      marginBottom: 30
    },
    '& div': {
      display: 'flex',
      '& button': {
        margin: '0 15px',
        '& span': {
          color: colors.WHITE
        }
      }
    }
  }
}));

const Confirmation = () => {
  const classes = useStyles();
  return(
    <div className={classes.wrapper}>
      <Seo title="Confirmation" />
      <h1>Merci de votre intérêt<span>Thanks for your interest</span></h1>
      <MagnetoBlog/>
      <h2>Visitez notre blogue pour en apprendre plus!<span>Visit our blog to learn more!</span></h2>
      <div>
      <Link to="/blogue"><InnerLinkButton text="Blogue" /></Link>
      <Link to="/en/blog"><InnerLinkButton text="Blog" /></Link>
      </div>
    </div>
  )
}

export default Confirmation
